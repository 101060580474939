@use './setting' as setting;

$disabled-button-color: rgba(0, 0, 0, 0.26);

// Mixins
@mixin reset-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

// Override Material Design styles
.mdc-text-field {
  &.mdc-text-field--filled,
  .mat-mdc-text-field-wrapper:hover,
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }

  padding: 0 !important;
  height: 50px !important;
}

.mat-mdc-form-field {
  &-hint-wrapper,
  &-error-wrapper {
    padding: 0 !important;
  }
}

// Datepicker styles
.mat-datepicker-content {
  // 月火水木金土日の文字色
  --mat-datepicker-calendar-header-text-color: #{setting.$basic_white} !important;
}

// カレンダーの次月、前月、当月のボタンスタイル
.mat-calendar {
  &-next-button,
  &-previous-button,
  &-period-button {
    @include reset-button;
    color: setting.$basic_white !important;

    &.mat-mdc-button-disabled {
      color: $disabled-button-color !important;
    }
  }
}

// カレンダーを開くカレンダーアイコンボタンスタイル
.mat-mdc-icon-button[aria-label="Open calendar"] {
  &.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 22px !important;
    font-size: 1em;
    padding: 11px 0 0 0 !important;

    .mdc-button__label,
    .mat-mdc-button-touch-target {
      margin: 0 !important;
      padding: 0 !important;
    }

    // ホバー時のリプルを非表示にする。要素サイズも小さく
    .mat-mdc-button-persistent-ripple {
      width: 22px;
      height: 22px;
      top: 0;
      left: 0;

      &::before {
        display: none;
      }
    }
  }
}

// Calendar content styles
.mat-datepicker-content.mat-datepicker-content {
  overflow: hidden;

  .mat-calendar {
    height: 338px;
  }
}

// Calendar header styles
.mat-calendar-header {
  background-color: setting.$color-base-darker-green;

  .mat-calendar-controls {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding-bottom: 8px;
  }

  .mat-calendar-previous-button { order: 1; }
  .mat-calendar-period-button { order: 2; }
  .mat-calendar-next-button { order: 3; }
  .mat-calendar-spacer { display: none; }
}

// Calendar body styles
mat-calendar-header + .mat-calendar-content {
  padding: 0;

  mat-month-view,
  mat-multi-year-view,
  mat-year-view {
    display: block;
    padding: 0 8px 8px;
  }

  mat-month-view {
    background: linear-gradient(
        180deg,
        setting.$color-base-darker-green 0%,
        setting.$color-base-darker-green 30px,
        setting.$basic-white 30px,
        setting.$basic-white 100%
    );
  }

  .mat-calendar-table-header {
    border-bottom: none;
  }

  mat-month-view .mat-calendar-body-label {
    opacity: 0;

    &[colspan='7'] {
      display: none;
    }
  }

  .mat-calendar-table-header-divider::after,
  abbr[title].mat-calendar-abbr {
    display: none;
  }

  abbr[title].mat-calendar-abbr {
    border-bottom: none;
    text-decoration: none;
  }
}
