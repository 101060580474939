@use 'sass:math';

$font-family: Lato, 'Noto Sans JP', '游ゴシック Medium', '游ゴシック体', 'Yu Gothic Medium',
    YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo,
    'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

$fs-10: 10px;
$fs-12: 12px;
$fs-14: 14px;
$fs-16: 16px;
$fs-18: 18px;
$fs-20: 20px;

$lh-10: 1;
$lh-12: 1.2;
$lh-14: 1.4;
$lh-16: 1.6;
$lh-18: 1.8;
$lh-20: 2;

$mg-10: 10px;
$mg-12: 12px;
$mg-14: 14px;
$mg-16: 16px;
$mg-18: 18px;
$mg-20: 20px;
$mg-22: 22px;
$mg-24: 24px;
$mg-26: 26px;
$mg-28: 28px;
$mg-30: 30px;
$mg-32: 32px;
$mg-34: 34px;
$mg-36: 36px;
$mg-38: 38px;
$mg-40: 40px;

$basic-white: #fff;
$darker-green: #33B5AC;
$basic-green: #4ECDC4;
$basic-gray: #9AAAAC;
$light-gray: #C5CED0;
$lighter-gray: #DBE1E1;
$lightest-gray: #F0F3F3;
$basic-black: #293132;
$lightest-red: #FEF0F0;
$darker-red: #EE4343;
$basic-red: #F27272;
$lightest-red: #FEF0F0;


/*************************************************
 * Musubi Material Design 色
 * 将来的にはこちらの色を利用していく
 * URL: https://www.figma.com/file/v4Za5cfLQzukZ2boxaZwRr/Musubi-Material-Design-System_r0.0.0
 *************************************************/

$color-base-dark-black: #293132;

$color-base-yellow-dark: #ffe045;

$color-base-darker-orange: #e88905;

$color-base-lightest-orange: #ffecd2;

$color-base-lightest-green: #e7f8f7;

$color-base-dark-gray: #708588;

$color-base-darker-gray: #4c5b5d;

$color-base-light-green: #8bded8;

$color-base-darker-green: #33b5ac;

$color-base-darkest-green: #2da199;

$color-base-lightest-blue: #e8f6ff;

$color-base-musubi-blue: #4bbcff;

$color-base-darker-blue: #0085d4;

$color-base-musubi-gray: #9aaaac;

$color-base-light-gray: #c5ced0;

$color-base-lighter-gray: #dbe1e1;

$color-base-lightest-gray: #f0f3f3;

$color-base-musubi-red: #f27272;

$color-base-darker-red: #ee4343;

$color-base-lightest-red: #fef0f0;
