@use 'setting' as setting;

body, div, span, a, object, iframe, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, abbr, address, cite, code, del, dfn, em, ins, kbd,
q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd,
ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, main, canvas, details, figcaption, figure,
footer, header, menu, nav, section, summary, time, mark, audio, video, button,
textarea, input, select, option  {
    font-family: setting.$font-family;
}
