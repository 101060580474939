@use 'setting' as setting;

@mixin button-base {
    font-size: setting.$fs-16;
    line-height: setting.$lh-10;
    padding: setting.$mg-12 setting.$mg-18;
    border-radius: 3px;
    min-width: 100px;
    border: initial;
    cursor: pointer;

    &:disabled {
        opacity: .5;
        cursor: initial;
    }
}

@mixin scrollbar-style {
    &::-webkit-scrollbar {
        width: 8px;
        background: #e6e6e6;
        &:horizontal {
            height: 8px;
        }
    }
    &::-webkit-scrollbar-thumb {
        background: #b7b7b7;
    }
}

.type-center {
    text-align: center;
}

.type-flex {
    display: flex;
    column-gap: setting.$mg-12;
    width: fit-content;
}

.type-flex-column {
    @extend .type-flex;
    flex-direction: column;
}

.type-sprit {
    display: flex;
    column-gap: setting.$mg-12;
    & > * {
        flex: 1;
    }
}

.type-w100 {
    width: 100px;
}

.type-h24 {
  height: 24px;
}


.is-hidden {
    visibility: hidden;
}

body {
    @include scrollbar-style;
    height: -webkit-fill-available;
}

#root {
    height: -webkit-fill-available;
}

.glb-error-alert {
    display: flex;
    align-items: center;
    padding: 0 setting.$mg-16;
    min-height: calc(setting.$mg-12 * 4);
    border-radius: calc(setting.$mg-12 / 2);
    background: setting.$lightest-red;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: calc(setting.$mg-40 * -4);
    width: calc(setting.$mg-40 * 8);
    margin-top: -#{setting.$mg-26};
    z-index: 98;
    filter: drop-shadow(0 0 calc(setting.$mg-12 / 2) setting.$light-gray);
    border: 2px solid setting.$basic-red;
    visibility: hidden;
    opacity: 0;
    height: 0;
    &:before {
        display: block;
        left: setting.$mg-16;
        width: 20px;
        height: 20px;
        margin-right: setting.$mg-16;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAaVBMVEUAAADycnLycnLvcHDycnL3cHDvcHDxcnLxcnL0cHDycXHxcXHzcXH/cHD0cnLzcHDxcXHycHDzcXHzcnLycnLycXHycnL0cnLzcnL0c3PzdHTzc3PzcnLycXHvcHDxcXHzc3PxcHDycnKLxlkBAAAAInRSTlMA798QYCAgcIAw35C/EJ9AoGDPr5+g0I+Pb0DPf7AwsL9w5ndM8AAAAVFJREFUeF6NlOlygzAMhCUfYCAcgZaWkPTg/R+yyHVHGIGb/ePg+dgdKUIQq70PCpcF58Hd4FT2vjIs1dhDrH5fhI7QidyElNlzwQ4bU2gAXZguvPgWx148Vl41sIzy5GV7N3q3HHbK0ZO73DIDoUxF6Ya4kThJ9kSGKKtiP+mJ2oOv9HPDkceGxL9wy+ZHIOT0rINhCecgULgL5zUFUq0I0PojBWrq5g3cCr4kQeh8EQ9OliBnf8K8gh9psKAxAqTi06CmMuT1yc3T4PPRiouR4mJ63x4jAdmeXDRciKbBQRvmLSH/F/4eJjkUYbwc9T0FqjAN+p/Bneg5o4eK2mTPQItkyPOmiBTiL4+/ivmQtOPCY0jh7Cn9loovvo5XyrRfKaB7ueRquaRIFa+9eoUK05Ab58ZLTgpzEMo6yVUhVqCRKzrGhFo39LgyavhuY+AHn0w9QK4TjyIAAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        background-size: 20px;
        content: '';
    }
    &.is-fade-in {
        animation-name: fadeIn;
        animation-duration: 500ms;
        animation-fill-mode: forwards;
      }
    &.is-fade-out {
        animation-name: fadeOut;
        animation-duration: 500ms;
        animation-fill-mode: forwards;
    }
    @keyframes fadeIn {
        0% {
            visibility: hidden;
            height: initial;
            opacity: 0;
        }
        1% {
            visibility: visible;
            height: initial;
            opacity: .01;
        }
        100% {
            visibility: visible;
            height: initial;
            opacity: 1;
        }
    }
    @keyframes fadeOut {
        0% {
            visibility: visible;
            height: initial;
            opacity: 1;
        }
        99% {
            visibility: visible;
            height: initial;
            opacity: .01;
        }
        100% {
            visibility: hidden;
            height: initial;
            opacity: 0;
        }
    }
}

.glb-article,
.glb-step-article {
    max-width: 600px;
    margin: 0 auto;
    padding: setting.$mg-16;
    line-height: setting.$lh-14;
    color: setting.$basic-black;
}

.glb-heading1 {
    font-size: setting.$fs-18;

    & + .glb-paragraph {
        margin-top: setting.$mg-30;
    }
}

.glb-heading2,
.glb-heading3 {
    font-size: setting.$fs-16;
}

.glb-paragraph {
    font-size: setting.$fs-16;

    & + .glb-delimited-frame {
        margin-top: setting.$mg-22;
    }
}

.glb-delimited-frame {
    padding: setting.$mg-16 setting.$mg-26;
    border: 1px solid #CCCCCC;
    border-radius: 3px;

    .glb-paragraph {
        margin: 0;
    }

    .glb-form-element-wrap{
        margin-top: setting.$mg-16;
    }

    .glb-paragraph,
    .glb-checkbox-label {
        font-size: setting.$fs-14;
    }

    .glb-outline-button {
        font-size: setting.$fs-14;
        padding-top: calc(setting.$mg-10 - 3px);
        padding-bottom: calc(setting.$mg-10 - 3px);
    }

    & + .glb-button-list {
        margin-top: setting.$mg-40;
    }
}


.glb-button-list {
    display: flex;
    list-style: none;
    padding: initial;
    column-gap: calc(setting.$mg-26 * 2);

    &.type-center {
        justify-content: center;
    }

    &.type-right {
        justify-content: right;
    }
}

.glb-oredered-list {
    list-style: none;
    padding: initial;
    .glb-oredered-list {
        padding-left: setting.$mg-40;
        margin: setting.$mg-12 0;
    }
    .glb-list-item {
        margin: setting.$mg-12 0;
    }
    .glb-paragraph {
        margin: initial;
    }
}

.glb-unordered-list {
    list-style: none;
    padding: initial;
    .glb-list-item {
        margin: setting.$mg-12 0;
    }
}

.glb-checkbox-list {
    display: flex;
    gap: setting.$mg-12 setting.$mg-16;
    flex-wrap: wrap;
    list-style: none;
    padding: initial;
    .glb-checkbox-item {
        margin: 0;
        .glb-form-element-label {
            width: fit-content;
        }
        &.type-hidden-item {
            height: 0;
            width: 0;
            overflow: hidden;
        }
    }
}

.glb-radio-list {
    display: flex;
    gap: setting.$mg-12 setting.$mg-16;
    flex-wrap: wrap;
    list-style: none;
    padding: initial;
    .glb-radio-item {
        margin: 0;
        .glb-form-element-label {
            width: fit-content;
        }
    }
}

.glb-outline-button {
    @include button-base;
    background: setting.$basic-white;
    color: setting.$darker-green;
    border: 2px solid setting.$darker-green;
    &.type-large {
        font-size: setting.$fs-18;
        padding-top: setting.$mg-16;
        padding-bottom: setting.$mg-16;
    }
}

.glb-fill-button {
    @include button-base;
    background: setting.$darker-green;
    color: setting.$basic-white;
    &.type-large {
        font-size: setting.$fs-18;
        padding-top: setting.$mg-16;
        padding-bottom: setting.$mg-16;
    }
}

.glb-text-button {
    @include button-base;
    background: transparent;
    color: setting.$darker-green;
    &.type-large {
        font-size: setting.$fs-18;
        padding-top: setting.$mg-16;
        padding-bottom: setting.$mg-16;
    }
}

.glb-form-element-label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    word-break: keep-all;

    .glb-form-element-prefix {
        margin-right: setting.$mg-12;
        min-width: setting.$mg-30;
        text-align: right;
    }

    .glb-form-element-suffix {
        margin-left: setting.$mg-12;
    }

    &.type-column {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 8px;
    }
}

.glb-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    & + .glb-checkbox-label {
        position: relative;
        cursor: pointer;
        display: inline-block;
        height: auto;
        line-height: initial;
        padding-left: setting.$mg-26;
        user-select: none;
        vertical-align: super;
        color: setting.$basic-black;
        &:before,
        &:after {
            display: block;
            position: absolute;
            top: 50%;
            box-sizing: border-box;
            content: "";
            transform: none;
        }
        &:before {
            opacity: 0;
            width: 8px;
            height: 13px;
            border-right: 2px solid setting.$basic-white;
            border-bottom: 2px solid setting.$basic-white;
            transform: rotate(45deg);
            left: 6px;
            margin-top: -8px;
            z-index: 1;
            border-top: none;
            border-left: none;
            transform-origin: initial
        }
        &:after {
            height: 20px;
            width: 20px;
            background: setting.$basic-white;
            border: 2px solid setting.$basic-gray;
            border-radius: 2px;
            margin-top: -9px;
            left: 0;
            z-index: 0;
        }
    }
    &:disabled {
        & + .glb-checkbox01-label {
            user-select: none;
            cursor: initial;
            color: setting.$basic-gray;
            &:after {
                color: setting.$basic-gray;
                border-color: setting.$basic-gray;
            }
        }
    }
    &:checked {
        & + .glb-checkbox-label {
            &:before {
                opacity: 1;
            }
            &:after {
                background-color: setting.$darker-green;
                border-color: setting.$darker-green;
            }
        }
    }
}

.glb-form-element-invalid-message {
    margin-top: calc(setting.$mg-10 / 2);
    color: setting.$darker-red;
    font-size: setting.$fs-12;
}

.glb-text-field {
    &[type="text"],
    &[type="number"],
    &[type="password"],
    &[type="email"],
    &[type="tel"],
    &[name^="textarea"] {
        width: 100%;
        border: 1px solid setting.$basic-gray;
        border-radius: 3px;
        height: auto;
        padding: 6px 10px;
        box-sizing: border-box;
        background: setting.$basic-white;
        font-size: setting.$fs-16;
        color: setting.$basic-black;
        &:focus-visible {
            outline: none;
        }
        &:focus {
            border-color: setting.$darker-green;
        }
        &:invalid,
        &.is-invalid {
            border-color: setting.$darker-red;
            background: setting.$lightest-red;
            color: setting.$darker-red;
        }
    }
    &[type="password"],
    &.type-password {
        padding-right: 40px;
    }
    &[name^="textarea"] {
        overflow: hidden;
        resize: none;
        min-height: 54px;
    }
}

.glb-radio {
    position: absolute;
    opacity: 0;
    & + .glb-radio-label {
        position: relative;
        cursor: pointer;
        display: inline-block;
        height: 20px;
        line-height: setting.$lh-12;
        user-select: none;
        padding-left: 26px;
        &:before,
        &:after {
            display: block;
            position: absolute;
            box-sizing: border-box;
            content: "";
        }
        &:before {
            opacity: 0;
            width: 10px;
            height: 10px;
            background: setting.$darker-green;
            border-radius: 50%;
            left: 5px;
            top: 5px;
            z-index: 1;
        }
        &:after {
            height: 20px;
            width: 20px;
            background: setting.$basic-white;
            border: 2px solid setting.$basic-gray;
            border-radius: 50%;
            top: 0;
            left: 0;
            z-index: 0;
        }
    }
    &:checked {
        & + .glb-radio-label {
            &:before {
                opacity: 1;
            }
            &:after {
                border-color: setting.$darker-green;
            }
        }
    }
}

.glb-dropdown {
    position: relative;
    &:before {
        display: block;
        position: absolute;
        border-top: 10px solid setting.$darker-green;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        content: '';
        z-index: 1;
        top: 50%;
        margin-top: -6px;
        right: 8px;
        cursor: pointer;
    }
    .glb-dropdown-wrap {
        position: relative;
        width: 100%;
        border: 1px solid setting.$basic-gray;
        border-radius: 3px;
        height: auto;
        padding: 6px 10px;
        box-sizing: border-box;
        background: setting.$basic-white;
        font-size: setting.$fs-16;
        color: setting.$basic-black;
        appearance: none;
        cursor: pointer;
        &:focus {
            border-color: setting.$darker-green;
        }
        &:focus-visible {
            outline: none;
        }
    }
}

.glb-privacypolicy-article {
    padding: setting.$mg-12 setting.$mg-18 calc(setting.$mg-12 / 2);

    .glb-privacypolicy-header {}

    .glb-privacypolicy-section {
        margin-top: setting.$mg-36;
    }

    .glb-privacypolicy-footer {
        margin-top: setting.$mg-20;
    }

    .glb-heading1,
    .glb-heading2 {
        font-size: setting.$fs-16;
    }
}

.glb-dialog {
    width: initial;
    height: initial;
    margin: initial;
    padding: initial;
    border: initial;
    max-width: initial;
    max-height: initial;
    @include scrollbar-style;
}

.glb-step-article {
    padding: 0 setting.$mg-16;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    @supports (-webkit-touch-callout: none) {
        min-height: -webkit-fill-available;
    }

    .glb-step-header {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        height: setting.$mg-32;
        width: 100vw;
        background: setting.$lightest-gray;
        z-index: 99;
        .glb-heading1 {
            margin: 0;
            font-size: setting.$fs-14;
            font-weight: normal;
        }
    }

    .glb-heading2 {
        margin-top: setting.$mg-16;
        padding-left: 6px;
        border-left: 4px solid setting.$basic-green;
        font-weight: normal;
        line-height: setting.$lh-12;
    }

    .glb-step-section-wrap {
        flex: 1;
        margin-top: setting.$mg-32;
        &.type-confirm {
            margin-top: 0;
            .glb-paragraph {
                margin-top: 124px;
            }
            .glb-button-list {
                margin-top: 147px;
            }
        }
    }

    .glb-step-section {
        border-bottom: 1px solid setting.$light-gray;
        &:last-of-type {
            border: none;
        }
        & > :last-child {
            margin-bottom: setting.$mg-16;
        }
        &.type-birthday {
            .glb-dropdown {
                margin-right: setting.$mg-28;
            }
        }
    }

    .glb-step-footer {
        border-top: 1px solid setting.$light-gray;
        margin-left: -#{setting.$mg-16};
        margin-right: -#{setting.$mg-16};
    }

    .glb-form-element-label {
        width: 100%;
        &.type-fit-content {
            width: fit-content;
        }
    }

    .type-w100 {
        width: 100px;
    }

    .glb-radio-item {
        min-width: 110px;
    }

    .glb-unordered-list {
        & + .glb-checkbox-list {
            margin-top: calc((setting.$mg-12 / -2));
        }
    }
}


.glb-last-article {
    padding: setting.$mg-16;

    .glb-paragraph:first-child {
        margin-top: calc(setting.$mg-36 * 3);
    }

    .glb-button-list {
        margin-top: calc(setting.$mg-24 * 7);
    }
}

.glb-tooltip {
    display: inline-flex;
    position: relative;

    &.type-text {
        border-bottom: 1px dashed;
        cursor: default;
    }

    &.type-align-center {
        .glb-tooltip01-message {
            text-align: center;
        }
    }

    &.type-align-right {
        .glb-tooltip01-message {
            text-align: right;
        }
    }

    .glb-tooltip-message {
        opacity: 0;
        position: absolute;
        width: max-content;
        padding: 10px;
        border-radius: 4px;
        background: #4C5B5D;
        font-size: setting.$fs-12;
        color: #fff;
        white-space: pre-line;
        z-index: -1;
        transition-property: opacity, top;
        transition-duration: .2s, .2s;
        transition-timing-function: ease-in-out;
        &.is-reverse {
            transition-property: opacity, bottom;
        }
        &.is-tooltip-visible {
            opacity: 1;
            transition-property: opacity, top;
            transition-duration: .2s, .2s;
            transition-timing-function: ease-in-out;
            &.is-reverse {
                transition-property: opacity, bottom;
            }
        }
    }
}
