/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "assets/styles/omi-edit.css";
// このファイルでは @use が使用できないため、その役割を別ファイルへ移譲
@import "assets/styles/style";

::ng-deep .toast-container .ngx-toastr {
  min-width: 300px !important;
  width: auto !important;
}
