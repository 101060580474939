.mg-0 {
  margin: 0! important;
}
.mt-11{
  margin-top: 5px!important;
}

.element-invalid-message {
  color: #ee4343 !important;
}
.element-invalid-border {
  border-color:#ee4343!important;
  background:#fef0f0!important;
  color:#ee4343!important;
}
.mt-3 {
  margin-top: 1rem!important;
}
.w-100 {
  width: 100% !important;
}
.fix-item {
  width: 50%;
  display: inline-flex;
  align-items: center;
}
.white-space{
  white-space: nowrap;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.d-grid{
  display: grid!important;
}

.glb-number {
  width: 100%;
  border: 1px solid #9aaaac;
  border-radius: 3px;
  height: auto;
  padding: 6px 10px;
  box-sizing: border-box;
  background: #fff;
  font-size: 16px;
  color: #293132
}
.glb-number:focus {
  border-color: #33b5ac !important;
  outline: none;
}
.glb-number::-webkit-inner-spin-button,
.glb-number::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance:textfield;
}
.element-invalid-border-select {
  border-color:#ee4343!important;
  background:#fef0f0!important;
}
